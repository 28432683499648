.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.google-btn {
  background-color: #4285F4;
  color: white;
  width: 240px;
  height: 50px;
  border-radius: 2px;
  border: none;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.animated-gradient {
	background: linear-gradient(-45deg, black, black, #115182, #1f8a91, white, white);
  backdrop-filter: blur(100px);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
  opacity:
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}



.google-btn svg {
  margin-right: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes dropAndPlop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

.plop-animation {
  animation: dropAndPlop 0.9s ease-out forwards;
}

.no-scroll {
  overflow: hidden;
}

.gradient-underline {
  position: relative;
  cursor: pointer;
  color: black; /* or any color */
}

.gradient-underline::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px; /* thickness of the underline */
  left: 0;
  bottom: -5px; /* distance of the underline from the text */
  background: linear-gradient(to right, #4c977f, #7a9ecf, #4c977f);
  visibility: hidden;
  transition: visibility 0.3s, transform 0.3s;
  transform: scaleX(0);
  transform-origin: left center;
  margin-bottom: 4px;
}

.gradient-underline:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.gradient-text:hover{
  background: linear-gradient(to right, #1b2a8f , #26164f, #1b2a8f);
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite, bounce 3s infinite;
  background-clip: text;
  -webkit-background-clip:text;
  color:rgba(0,0,0,1);
  transition: color .2s ease-in-out;
}
.gradient-text:hover{
  color:rgba(1,0,0,0);
}
@keyframes rainbow { 
  0%, 100% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
}

.gradient-text-on{
  background: linear-gradient(to right, #4285f4, #0cc481 , #e8b007);
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite, bounce 3s infinite;
  background-clip: text;
  -webkit-background-clip:text;
  color:rgba(0,0,0,1);
  transition: color .2s ease-in-out;
}
.gradient-text-on{
  color:rgba(1,0,0,0);
}
@keyframes rainbow { 
  0%, 100% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1); /* Normal size */
  }
  50% {
    transform: scale(1.03); /* Slightly larger */
  }
}


.App-header {
  background-color: #185adf;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#greet {
    background: -webkit-linear-gradient(16deg,#4b90ff,#0abe5e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


